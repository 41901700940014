
function cjBindImagePreview() {
    if (!$('.cj-image-preview').length) {
        var modal = $('<div class="modal fade" id="cjImagePreviewModal" tabindex="-1" role="dialog" aria-labelledby="cjImagePreviewModalLabel" aria-hidden="true"></div>');
    }
    $('.cj-image-preview').on('click', function () {
        var src = $(this).attr('src');
        var modal = $('#cjImagePreviewModal');
        modal.find('.modal-body img').attr('src', src);
        modal.modal('show');
    });
}
(function ($) {

})(jQuery);

//
// $.fn.dataTable.ext.order['dom-checkbox'] = function  ( settings, col )
// {
//   console.dir(col);
//     return this.api().column( col, {order:'index'} ).nodes().map( function ( td, i ) {
//         return $('input', td).prop('checked') ? '1' : '0';
//     } );
// };
//
//
// jQuery.extend(jQuery.fn.dataTableExt.oSort, {
//     "num-html-pre": function (a) {
//         var x = $(a).first().text();
//         if (x == '')x = '0';
//         return parseFloat(x);
//     },
//
//     "num-html-asc": function (a, b) {
//         console.log(a + "-" + b);
//         return ((a < b) ? -1 : ((a > b) ? 1 : 0));
//     },
//
//     "num-html-desc": function (a, b) {
//         return ((a < b) ? 1 : ((a > b) ? -1 : 0));
//     }
// });
