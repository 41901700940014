(function($) {
    $(document).ready(function() {
        var show = Cookies.get('sidenav-collapse');
        var nav = $('#sidebarNav');
        if (!show) {
            nav.removeClass('show')
        }
        nav.on('hide.bs.collapse show.bs.collapse', event => {
            Cookies.set('sidenav-collapse', !nav.hasClass('show'))
        })

    });
})(jQuery);