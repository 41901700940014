(function ($) {
    $(document).ready(function () {
        // Document is ready

        $(".dt-search").bind("keyup", function () {
            $('table', $(this).closest('.main-box')).DataTable().search($(this).val()).draw();
        });

        $("form").submit(function (e) {
            $("table.dataTable", this).DataTable().destroy();
            return true;
        });

        if ($.fn.dataTable) {
            $.fn.dataTable.ext.order['dom-text-numeric'] = function (settings, col) {
                return this.api().column(col, {order: 'index'}).nodes().map(function (td, i) {
                    return jQuery('input', td).val() * 1;
                });
            };
        }


        $.fn.dataTable.ext.order['dom-checkbox'] = function (settings, col) {
            return this.api().column(col, {order: 'index'}).nodes().map(function (td, i) {
                return $('input', td).prop('checked') ? '1' : '0';
            });
        };


        jQuery.extend(jQuery.fn.dataTableExt.oSort, {
            "num-html-pre": function (a) {
                var x = $(a).first().text();
                if (x == '') x = '0';
                return parseFloat(x);
            },

            "num-html-asc": function (a, b) {
                console.log(a + "-" + b);
                return ((a < b) ? -1 : ((a > b) ? 1 : 0));
            },

            "num-html-desc": function (a, b) {
                return ((a < b) ? 1 : ((a > b) ? -1 : 0));
            }
        });

    });

    //
    // $('.fixed-leftmenu #col-left').nanoScroller({
    //     alwaysVisible: true,
    //     iOSNativeScrolling: false,
    //     preventPageScrolling: true,
    //     contentClass: 'col-left-nano-content'
    // });
    //
    // // build all tooltips from data-attributes
    // $("[data-toggle='tooltip']").each(function (index, el) {
    //     $(el).tooltip({
    //         placement: $(this).data("placement") || 'top'
    //     });
    // });

    $.fn.removeClassPrefix = function (prefix) {
        this.each(function (i, el) {
            var classes = el.className.split(" ").filter(function (c) {
                return c.lastIndexOf(prefix, 0) !== 0;
            });
            el.className = classes.join(" ");
        });
        return this;
    };

    function showAjaxLoadingOverlay(msg) {
        closeAjaxLoadingOverlay();
        // add the overlay with loading image to the page
        var over = '<div id="cj_overlay">' +
            '<span id= "cj_loading"><img  src="/img/loading.gif"><h2>' + msg + '</h2></span>'
        '</div>';
        $(over).appendTo('body');

        $("#cj_overlay").css("height", $(document).height() + "px");
        $("#cj_loading").css("top", $(document).scrollTop() + 100 + "px");
        $("#cj_loading").css("left", $(document).width() / 2 - 200 + "px");
        // hit escape to close the overlay
        $(document).keyup(function (e) {
            if (e.which === 27) {
                $('#cj_overlay').remove();
            }
        });
    }

    function closeAjaxLoadingOverlay() {
        $('#cj_overlay').remove();
    }



    function ignoreOwner() {
        jQuery.cookie("ignore_owner", 1, {expires: 60 * 60 * 24});
    }

    function ignoreGod() {
        jQuery.cookie("ignore_god", 1, {expires: 60 * 60 * 24});
    }

// //because im lazy
// !function (e) {
//     "function" == typeof define && define.amd ? define(["jquery"], e) : e("object" == typeof exports ? require("jquery") : jQuery)
// }(function (e) {
//     function n(e) {
//         return u.raw ? e : encodeURIComponent(e)
//     }
//
//     function o(e) {
//         return u.raw ? e : decodeURIComponent(e)
//     }
//
//     function i(e) {
//         return n(u.json ? JSON.stringify(e) : String(e))
//     }
//
//     function r(e) {
//         0 === e.indexOf('"') && (e = e.slice(1, -1).replace(/\\"/g, '"').replace(/\\\\/g, "\\"));
//         try {
//             return e = decodeURIComponent(e.replace(c, " ")), u.json ? JSON.parse(e) : e
//         } catch (n) {
//         }
//     }
//
//     function t(n, o) {
//         var i = u.raw ? n : r(n);
//         return e.isFunction(o) ? o(i) : i
//     }
//
//     var c = /\+/g, u = e.cookie = function (r, c, a) {
//         if (arguments.length > 1 && !e.isFunction(c)) {
//             if (a = e.extend({}, u.defaults, a), "number" == typeof a.expires) {
//                 var f = a.expires, s = a.expires = new Date;
//                 s.setTime(+s + 864e5 * f)
//             }
//             return document.cookie = [n(r), "=", i(c), a.expires ? "; expires=" + a.expires.toUTCString() : "", a.path ? "; path=" + a.path : "", a.domain ? "; domain=" + a.domain : "", a.secure ? "; secure" : ""].join("")
//         }
//         for (var d = r ? void 0 : {}, p = document.cookie ? document.cookie.split("; ") : [], m = 0, x = p.length; x > m; m++) {
//             var l = p[m].split("="), g = o(l.shift()), k = l.join("=");
//             if (r && r === g) {
//                 d = t(k, c);
//                 break
//             }
//             r || void 0 === (k = t(k)) || (d[g] = k)
//         }
//         return d
//     };
//     u.defaults = {}, e.removeCookie = function (n, o) {
//         return void 0 === e.cookie(n) ? !1 : (e.cookie(n, "", e.extend({}, o, {expires: -1})), !e.cookie(n))
//     }
// });

})(jQuery);
function autoHidePager (settings, table) {
    var api = jQuery.fn.dataTable.Api(settings);
    // Output the data for the visible rows to the browser's console
    jQuery(".pagination", settings.nTableWrapper).css("display",
        api.page.info().recordsTotal > api.page.len() ? 'inline-block' : 'none'
    );
}
